"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockedContractorsColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getBlockedContractorsColumns = function (props) {
    var onSubmit = props.onSubmit;
    return [
        {
            field: 'contractorName',
            filterable: false,
            flex: 400,
            headerName: 'Контрагент',
            minWidth: 220,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442", onSubmit: onSubmit('name'), id: "name" }); },
        },
        {
            field: 'contractorInn',
            sortable: false,
            filterable: false,
            flex: 200,
            headerName: 'ИНН',
            minWidth: 110,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0418\u041D\u041D", onSubmit: onSubmit('inn'), id: "inn" }); },
        },
        {
            field: 'contractorOgrn',
            filterable: false,
            sortable: false,
            flex: 200,
            headerName: 'ОГРН',
            minWidth: 140,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041E\u0413\u0420\u041D", onSubmit: onSubmit('ogrn'), id: "ogrn" }); },
        },
        {
            field: 'contractorKpp',
            filterable: false,
            sortable: false,
            flex: 200,
            headerName: 'КПП',
            minWidth: 110,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041A\u041F\u041F", onSubmit: onSubmit('kpp'), id: "kpp" }); },
        },
        {
            field: 'scoringResultPeriod',
            filterable: false,
            flex: 220,
            minWidth: 140,
            headerName: 'Дата блокировки',
            sortable: false,
            valueFormatter: function (params) {
                return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.ShortTextDateTime);
            },
        },
    ];
};
exports.getBlockedContractorsColumns = getBlockedContractorsColumns;
