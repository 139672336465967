"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApproverGroupColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var permissions_1 = require("../../../ud-ui/helpers/permissions");
var getApproverGroupColumns = function (props) {
    var onEdit = props.onEdit, onDelete = props.onDelete, onSubmit = props.onSubmit;
    var canChange = (0, permissions_1.hasPermissionInStorage)('processWrite');
    return [
        {
            field: 'name',
            filterable: false,
            flex: 400,
            headerName: 'Наименование',
            minWidth: 200,
            sortable: false,
            type: 'string',
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435", onSubmit: onSubmit('name'), id: "name" }); },
        },
        {
            field: 'description',
            filterable: false,
            flex: 400,
            headerName: 'Описание',
            minWidth: 200,
            sortable: false,
            type: 'string',
        },
        {
            editable: false,
            field: '',
            filterable: false,
            sortable: false,
            width: 240,
            renderCell: function (params) {
                return canChange && ((0, jsx_runtime_1.jsxs)(material_1.Box, __assign({ sx: { display: 'flex', gap: 3 } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "primary", "data-id": "editButton", onClick: function () { return onEdit(params.row.id); } }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })), (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "error", "data-id": "deleteButton", onClick: function () { return onDelete(params.row); } }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }))] })));
            },
        },
    ];
};
exports.getApproverGroupColumns = getApproverGroupColumns;
