"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpertsColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var icon_info_1 = require("../../../ud-ui/components/icon-info");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getExpertsColumns = function (props) {
    var onSubmit = props.onSubmit;
    return [
        {
            field: 'name',
            filterable: false,
            flex: 400,
            headerName: 'ФИО',
            minWidth: 200,
            sortable: false,
            renderCell: function (params) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [params.row.name, params.row.isBlocked && (0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { type: constants_1.IconInfoType.BlockedExpert, dataId: "blockedExpertIcon" })] })); },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0424\u0418\u041E", onSubmit: onSubmit('name'), id: "name" }); },
        },
        {
            field: 'expertOrganization.name',
            filterable: false,
            flex: 400,
            headerName: 'Экспертная организация',
            minWidth: 200,
            sortable: false,
            renderCell: function (params) {
                var _a, _b;
                return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(_a = params.row.expertOrganization) === null || _a === void 0 ? void 0 : _a.name, ((_b = params.row.expertOrganization) === null || _b === void 0 ? void 0 : _b.unscrupulousExpertOrganization) && ((0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { dataId: "unscrupulousExpertOrganizationIcon", sx: { marginLeft: 1 }, type: constants_1.IconInfoType.BlockedContractor }))] }));
            },
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u042D\u043A\u0441\u043F\u0435\u0440\u0442\u043D\u0430\u044F \u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F", onSubmit: onSubmit('expertOrganizationName'), id: "expertOrganizationName" })); },
        },
    ];
};
exports.getExpertsColumns = getExpertsColumns;
