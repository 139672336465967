"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventModelColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var helpers_1 = require("../../../../helpers");
var contractor_icons_info_1 = require("../../../ud-ui/components/contractor-icons-info");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getEventModelColumns = function (props) {
    var onSubmit = props.onSubmit;
    return [
        {
            field: 'period',
            filterable: false,
            headerName: 'Период',
            width: 130,
            sortingOrder: ['desc', 'asc'],
            renderCell: function (params) { return (0, helpers_1.getFormattedDate)(params.value); },
        },
        {
            field: 'contractor',
            filterable: false,
            flex: 200,
            headerName: 'Контрагент',
            minWidth: 200,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { id: "contractorName", name: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442", onSubmit: onSubmit('contractorName') }); },
            renderCell: function (params) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [params.value.name, (0, jsx_runtime_1.jsx)(contractor_icons_info_1.ContractorIconsInfo, { contractor: params.value })] })); },
        },
        {
            field: 'contractor.inn',
            filterable: false,
            headerName: 'ИНН',
            sortable: false,
            minWidth: 140,
            renderCell: function (params) { return params.row.contractor.inn; },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { id: "contractorInn", name: "\u0418\u041D\u041D", onSubmit: onSubmit('contractorInn') }); },
        },
        {
            field: 'riskFactor',
            filterable: false,
            flex: 400,
            headerName: 'Риск-фактор',
            sortable: false,
            renderCell: function (params) { return params.value.name; },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { id: "riskFactorName", name: "\u0420\u0438\u0441\u043A-\u0444\u0430\u043A\u0442\u043E\u0440", onSubmit: onSubmit('riskFactorName') }); },
        },
        {
            field: 'value',
            filterable: false,
            flex: 200,
            headerName: 'Значение',
            sortable: false,
            renderCell: function (params) { return params.value.value; },
        },
    ];
};
exports.getEventModelColumns = getEventModelColumns;
